<template>
  <v-card
    color="neutral"
    class="rounded-xl"
  >
    <v-card-title
      v-if="!isTitleHidden"
      primary-title
    >
      <div :class="secondClass">
        <div class="card-title">
          <v-icon left>
            {{ icon }}
          </v-icon>{{ title }}
        </div>
        <div
          v-if="extraTitle"
          class="left-title"
        >
          <span>{{ extraTitle }}</span>
          <v-icon
            v-if="secondIcon"
            style="cursor: pointer;"
            @click="onClick"
          >
            {{ secondIcon }}
          </v-icon>
        </div>
        <slot name="checkbox" />
        <slot name="select" />
      </div>
    </v-card-title>
    <v-card-text :class="cardTextClass">
      <slot />
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'CustomCard',
  props: {
    isTitleHidden: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    extraTitle: {
      type: String,
      default: ''
    },
    secondIcon: {
      type: String,
      default: ''
    },
    onClick: {
      type: Function,
      default: function () {}
    },
    secondClass: {
      type: String,
      default: ''
    },
    cardTextClass: {
      type: String,
      default: ''
    }
  } // add extraTitle, secondIcon and onclick if the header contains a right icon or text
}
</script>
