<template>
  <v-col cols="12">
    <div class="top-card table-card">
      <div
        is="CustomCard"
        title="Receipts &amp; Invoices"
        icon="receipt"
        card-text-class="card-table"
        second-class="title-container-card stats-card-top input-no-padding"
      >
        <v-data-table
          :loading="tableLoader"
          :options.sync="pagination"
          :headers="headers"
          :items="receipts"
          hide-default-footer
          item-key="id"
        >
          <v-progress-linear
            slot="progress"
            color="blue"
            indeterminate
          />
          <template
            slot="items"
            slot-scope="props"
          >
            <tr class="cursor-pointer">
              <td @click="goToTransaction(props.item.id)">
                <span v-if="props.item.createdAt">
                  {{ formatDate.basic(props.item.createdAt) }}
                </span>
              </td>
              <td
                class="type-receipt"
                @click="goToTransaction(props.item.id)"
              >
                <span v-if="props.item.type">
                  {{ props.item.type }}
                </span>
              </td>
              <td @click="goToTransaction(props.item.id)">
                <span v-if="props.item.transactionId">
                  {{ props.item.transactionId }}
                </span>
              </td>
              <td @click="goToTransaction(props.item.id)">
                <span v-if="props.item.total !== null && props.item.total !== undefined">
                  {{ props.item.total }}
                  <span v-if="props.item.currency">
                    {{ props.item.currency.toUpperCase() }}
                  </span>
                </span>
              </td>
              <td @click="goToTransaction(props.item.id)">
                <v-chip
                  v-if="props.item.paid === true"
                  disabled
                  class="elevation-1 success-chip"
                  text-color="white"
                >
                  <v-icon>mdi-check</v-icon>
                </v-chip>
                <v-chip
                  v-if="props.item.paid === false"
                  disabled
                  class="elevation-1 neutral-chip"
                  text-color="white"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-chip>
              </td>
              <td
                class="justify-end layout px-0 pr-4"
                @click="goToTransaction(props.item.id)"
              >
                <v-tooltip
                  v-if="props.item.stripePdfUrl"
                  color="light-blue darken-4"
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn
                      class="my-1 mx-0"
                      fab
                      text
                      small
                      v-on="on"
                      @click.stop="downloadFile(props.item.stripePdfUrl)"
                    >
                      <v-icon>mdi-text-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Download invoice</span>
                </v-tooltip>
                <v-tooltip
                  v-if="props.item.s3url"
                  color="light-blue darken-4"
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn
                      class="my-1 mx-0"
                      fab
                      text
                      small
                      v-on="on"
                      @click.stop="downloadFile(props.item.s3url)"
                    >
                      <v-icon>mdi-receipt</v-icon>
                    </v-btn>
                  </template>
                  <span>Download receipt</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template #no-data>
            <div class="no-data-container">
              <img
                src="@/assets/img/sadGuy.svg"
                class="login-denied-logo no-data-svg"
                alt="Sad"
              >
              <p>Sorry, nothing to display here</p>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- <div class="button-container">
        <v-btn :disabled="receiptsBtn" class="cta-btn no-right-margin" @click="getMoreReceipts()">See More</v-btn>
    </div>-->
  </v-col>
</template>

<script>
import CustomCard from '@/common/CustomCard.vue'
import { formatDate } from '@/config/formatDate.js'
export default {
  name: 'Receipts',
  components: {
    CustomCard
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {}
    },
    route: {
      type: String,
      default: 'verifications'
    }
  },
  data () {
    return {
      formatDate: formatDate,

      selectedReceipt: '',
      pagination: {
        sortBy: 'createdAt',
        descending: true
      },
      headers: [
        {
          text: 'Date',
          value: 'createdAt'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Reference ID',
          value: 'transactionId'
        },
        {
          text: 'Price',
          value: 'total'
        },
        {
          text: 'Paid',
          value: 'paid'
        },
        {
          text: '',
          sortable: false
        }
      ],
      receipts: [],
      receiptsPage: 1,
      receiptsBtn: false,
      tableLoader: false
    }
  },
  beforeMount () {
    this.getReceipts()
    this.$root.$emit('viewBilling')
  },
  methods: {
    getReceipts () {
      this.tableLoader = true
      this.$http.get(this.route + 's')
        .then(
          res => {
            this.receipts = res.data.verifications
            this.tableLoader = false
          },
          err => {
            console.error(err)
            this.tableLoader = false
          }
        )
    },
    goToTransaction (transactionId) {
      this.$router.push({
        name:
          this.route === 'verifications'
            ? 'user-bill'
            : 'company-bill',
        params: { id: transactionId }
      })
    },
    downloadFile (data) {
      if (data !== null) {
        window.open(data)
      } else {
        this.createAlert(
          'Could not reach URL at this moment',
          'priority_high',
          'warning'
        )
      }
    }
  }
}
</script>
